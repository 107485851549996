import { DashboardLayout } from "app/layout"
import { Button } from "shared/components/Button"
import { TabsWrapper, WrapperAvatar } from "./style"
import { useEffect, useRef, useState } from "react"
import { Tab, Tabs } from "shared/components/Tabs"
import { Avatar } from "shared/components/Avatar"
import { useNavigate, useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import moment from "moment"
import { StyledLine, WrapperFormProfile } from "shared/styles/GlobalStyle"
import { BackButton } from "shared/components/BackButton"
import { UploadFile } from "entities/uploadFiles"
import { Certificates } from "entities/certificates"
import { FormikInput } from "shared/components/FormikInput"
import { Form, Formik } from "formik"
import { getProfileClients } from "./api/getProfileClients"
import { useDispatch } from "react-redux"
import { handleEditProfile } from "./api/handleEditProfile"
import { closeModal, openModal } from "shared/redux/slice/modalSlice"
import { axiosApi } from "shared/api/axios"
import { showNotification } from "shared/redux/slice/notificationSlice"
import { OrdersTable } from "./ui/ordersTable"
import { UploadAvatar } from "shared/containers/UploadAvatar"
import IMask from "imask"
import { ValidateSchemaPersonal } from "pages/clientsCreate/model/validateSchemaPersonal"
import { useFileUploadUp } from "shared/hooks/useFileUploadUp"
import { getChangedValues } from "shared/utils/getChangedValues"
import { CLIENTSLIST } from "shared/consts/routingConsts"
import { Loader } from "shared/components/Loader"
import { getInitialValuesPersonal } from "./model/getInitialValues"

interface IPropsFile {
    id: number;
    name: string;
    hash_name?: string;
    read_url?: string;
    file?: any;
}

export const Profile = () => {
    const edition = "Редактировать";
    const save = "Сохранить";
    const personalFormRef = useRef<(() => void) | null>(null);
    const {id} = useParams();
    const navigate = useNavigate();
    const [isEditable, setIsEditable] = useState(false);
    const [editButtonText, setEditButtonText] = useState(edition);
    const [loading, setLoading] = useState<boolean>(false);
    const [photo, setPhoto] = useState<File | null>(null);

    const [name, setName] = useState<string>(""); // ФИО
    const [newFiles, setNewFiles] = useState<IPropsFile[]>([]);
    const [removedFiles, setRemovedFiles] = useState<IPropsFile[]>([]);

    const [isFormValid, setIsFormValid] = useState(false);
    
    const { handleFileUpload } = useFileUploadUp();

    const dispatch = useDispatch();

    const toggleEdit = () => {
        if (isEditable && personalFormRef.current) {
            personalFormRef.current();
        }
        setIsEditable(true);
        setEditButtonText(save);
    };

    const {isLoading, data, isSuccess, refetch} = useQuery({
        queryKey: ["getProfile", id],
        queryFn: () => getProfileClients(id),
        refetchOnWindowFocus: false,
    });

    const [currentPhoto, setCurrentPhoto] = useState<string | null>(data?.profile_photo?.read_url ?? null);

    const handlePhotoChange = (file: File) => {
        setPhoto(file);
        setCurrentPhoto(URL.createObjectURL(file));
    };

    useEffect(() => {
        if (!currentPhoto) {
            setCurrentPhoto(data?.profile_photo?.read_url ?? null);
        }
    }, [data?.profile_photo?.read_url, currentPhoto]);

    const cancelEdit = () => {
        setIsEditable(false);
        setEditButtonText(edition);
        refetch();
        dispatch(closeModal());
    }

    useEffect(() => {
        setName(`${data?.contacts?.[0]?.surname} ${data?.contacts?.[0]?.first_name} ${data?.contacts?.[0]?.patronymic}`);
    }, [data]);

    const initialValuesCompany = {
        id: data?.id,
        type: data?.type,
        phone: data?.phone || "",
        email: data?.email || "",
        is_black_list: data?.is_black_list,
        comment: data?.comment || "",
        created_at: moment(data?.created_at).format("DD.MM.YYYY"),
        discount: data?.discount || 0,
        additional_data: {
            documents: {
                organization_name: data?.additional_data?.documents?.organization_name || "",
                inn: data?.additional_data?.documents?.inn || "",
                bank_name: data?.additional_data?.documents?.bank_name || "",
                bik: data?.additional_data?.documents?.bik || "",
                kpp: data?.additional_data?.documents?.kpp || "",
                checking_account: data?.additional_data?.documents?.checking_account || "",
                legal_address: data?.additional_data?.documents?.legal_address || "",
                registration_date: data?.additional_data?.documents?.registration_date || "",
                correspondent_account: data?.additional_data?.documents?.correspondent_account || "",
            }
        },
        profile_photo: {
            hash_name: data?.profile_photo?.hash_name || "",
            read_url: data?.profile_photo?.read_url || ""
        },
        document_photos: data?.document_photos,
        contact: {
            phone: data?.contacts?.[0]?.phone || "",
            email: data?.contacts?.[0]?.email || "",
            surname: data?.contacts?.[0]?.surname || "",
            first_name: data?.contacts?.[0]?.first_name || "",
            patronymic: data?.contacts?.[0]?.patronymic || "",
        },
    };

    const handleRemoveBlacList = async (id: any) => {
        dispatch(closeModal());
        try {
            const response = await axiosApi.delete(`${process.env.API_BACKEND}/v1/counterparties/counterparty/black_list/`, {
                data: {
                    "counterparty_ids": [id],
                    "comment": ""
                }
            });
            if (response.status === 204) {
                dispatch(showNotification({ message: 'Успешно!', type: 'success' }));
                refetch();
            } else {
                dispatch(showNotification({ message: 'Ошибка!', type: 'error' }));
            }
        } catch (error) {
            dispatch(showNotification({ message: 'Ошибка!', type: 'error' }));
        }
    }

    const handleDeleteClients = async (id: any) => {
        dispatch(closeModal());
        try {
            const response = await axiosApi.delete(`${process.env.API_BACKEND}/v1/counterparties/counterparty/`, {
                data: {
                    counterparty_ids: [id]
                }
            });
            if (response.status === 204) {
                dispatch(showNotification({ message: 'Успешно!', type: 'success' }));
                navigate(CLIENTSLIST);
            } else {
                dispatch(showNotification({ message: 'Ошибка!', type: 'error' }));
            }
        } catch (error) {
            dispatch(showNotification({ message: 'Ошибка!', type: 'error' }));
        }
    };

    const handleAddBlackList = async (id: any, comment?: string) => {
        dispatch(closeModal());
        try {
            const response = await axiosApi.post(`${process.env.API_BACKEND}/v1/counterparties/counterparty/black_list/`, {
                counterparty_ids: [id],
                "comment": comment
            });
            if (response.status === 204) {
                dispatch(showNotification({ message: 'Успешно!', type: 'success' }));
                refetch();
            } else {
                dispatch(showNotification({ message: 'Ошибка!', type: 'error' }));
            }
        } catch (error) {
            dispatch(showNotification({ message: 'Ошибка!', type: 'error' }));
        }

    };

    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <BackButton
                    text="Назад"
                    onClick={() => navigate('/clients')}
                />
                <div className="dashboard__right-menu">
                    {isEditable ? <>
                        <Button requiredPermission="edit" themeType={isEditable ? "primary" : "outline-2"} loading={loading} bgLoading="white" onClick={toggleEdit} disabled={isFormValid}>{editButtonText}</Button>
                        <Button 
                        requiredPermission="add"
                        themeType="outline-2" 
                        onClick={() => dispatch(openModal({
                            isOpen: true,
                            content: (
                                <>Вы точно хотите отменить редактирование?</>
                            ),
                            config: {
                                title: "Вы точно хотите отменить?",
                                btns: [
                                    {
                                        onClick: () => cancelEdit(),
                                        title: "Да",
                                        active: true
                                    },
                                    {
                                        onClick: () => dispatch(closeModal()),
                                        title: "Нет",
                                        active: false
                                    }
                                ]
                            }
                        }))}
                    >Отменить</Button>
                    </> : 
                        <>
                        <Button requiredPermission="edit" themeType={"outline-2"} onClick={toggleEdit}>{editButtonText}</Button>
                        {data?.is_black_list ?
                            <Button 
                                requiredPermission="delete"
                                themeType="outline-2" 
                                onClick={() => dispatch(openModal({
                                    isOpen: true,
                                    content: (
                                        <div className="blockWithComment">
                                            Вы уверены, что хотите удалить пользователя<br />
                                            из черного списка?
                                        </div>
                                    ),
                                    config: {
                                        title: "Вы точно хотите удалить?",
                                        btns: [
                                            {
                                                onClick: () => handleRemoveBlacList(id),
                                                title: "Да",
                                                active: true
                                            },
                                            {
                                                onClick: () => dispatch(closeModal()),
                                                title: "Нет",
                                                active: false
                                            }
                                        ]
                                    }
                                })
                                )}
                            >
                                Удалить из черного списка
                            </Button>
                        :
                            <Button
                                requiredPermission="add"
                                themeType="outline-2" 
                                onClick={() => {
                                    let localComment = '';
                                    dispatch(openModal({
                                        isOpen: true,
                                        content: (
                                            <div className="blockWithComment">
                                                Вы уверены, что хотите добавить пользователя<br />
                                                в черный список?
                                                <textarea placeholder="Комментарий" onChange={(e) => localComment = e.target.value} />
                                            </div>
                                        ),
                                        config: {
                                            title: "Вы точно хотите удалить?",
                                            btns: [
                                                {
                                                    onClick: () => handleAddBlackList(id, localComment),
                                                    title: "Да",
                                                    active: true
                                                },
                                                {
                                                    onClick: () => dispatch(closeModal()),
                                                    title: "Нет",
                                                    active: false
                                                }
                                            ]
                                        }
                                    }));
                                }}
                            >
                                Добавить в черный список
                            </Button>
                        }
                            <Button 
                                requiredPermission="delete"
                                themeType="delete" 
                                onClick={() => dispatch(openModal({
                                    isOpen: true,
                                    content: (
                                        <>
                                            Вы точно хотите удалить профиль пользователя?
                                        </>
                                    ),
                                    config: {
                                        title: "Вы точно хотите удалить?",
                                        btns: [
                                            {
                                                onClick: () => handleDeleteClients(id),
                                                title: "Да",
                                                active: true
                                            },
                                            {
                                                onClick: () => dispatch(closeModal()),
                                                title: "Нет",
                                                active: false
                                            }
                                        ]
                                    }
                                }))}
                            >
                                Удалить клиента
                            </Button>
                        </>

                    }
                </div>
            </div>
        }>
            {isLoading ? <Loader /> :
                <>
                    {(isSuccess && data?.type === 0) &&
                        <Formik
                            key="personalForm"
                            initialValues={getInitialValuesPersonal(data)}
                            validationSchema={ValidateSchemaPersonal}
                            onSubmit={async(values) => {
                                    setLoading(true);
                                    const { uploadedPhotoInfo, uploadedFilesInfo } = await handleFileUpload(photo, newFiles?.map(file => file.file!) || []);
                                    const updatedValues = {
                                        ...getChangedValues(getInitialValuesPersonal(data), values),
                                    };

                                    if (uploadedPhotoInfo) {
                                        updatedValues.profile_photo_hash_name = uploadedPhotoInfo.hash_name;
                                    }
                                    
                                    if (removedFiles.length > 0) {
                                        updatedValues.removed_document_photos_hash_names = removedFiles
                                            .map((file) => file?.hash_name)
                                            .filter((hash_name) => hash_name !== null && hash_name !== undefined);
                                        setRemovedFiles([]);
                                    }

                                    if (newFiles.length > 0) {
                                        updatedValues.new_document_photos_hash_names = uploadedFilesInfo.map((file, index) => ({
                                            hash_name: file.hash_name,
                                            file_name: newFiles[index].name
                                        }))
                                        setNewFiles([]);
                                    }
                                    
                                    handleEditProfile(
                                        updatedValues as any,
                                        refetch,
                                        setIsEditable,
                                        setEditButtonText,
                                        dispatch,
                                        id
                                    ).then(() => {
                                        setLoading(false);
                                    })
                                }
                            }
                            enableReinitialize
                        >
                            {({ submitForm, isValid }) => {

                                setIsFormValid(!isValid);
                                personalFormRef.current = submitForm;
                                return (
                                    <Form>
                                        <TabsWrapper>
                                            <Tabs>
                                                <Tab label="Профиль">
                                                    <WrapperAvatar>
                                                        {isEditable ? (
                                                            <UploadAvatar 
                                                                initialPhoto={currentPhoto}
                                                                onPhotoChange={handlePhotoChange}
                                                            />
                                                        ) : (
                                                                <Avatar photo={data?.profile_photo?.read_url}  name={name}  jobs="Физическое лицо" />
                                                            )
                                                        }

                                                    </WrapperAvatar>
                                                    <StyledLine margin="20px 0"/>
                                                    {isEditable && (
                                                        <WrapperFormProfile>
                                                            <div className="title">ФИО</div>
                                                            <div className="container">
                                                                <div className="row">
                                                                    <FormikInput
                                                                        name="contact.first_name"
                                                                        label="Имя" 
                                                                        placeholder={isEditable ? "Имя" : ""}
                                                                        className="inputForm"
                                                                        disabled={!isEditable}
                                                                        $afterIcon="clearInput"
                                                                        maskConfig={{mask: /^[A-Za-zА-Яа-яЁё\s]*$/}}
                                                                    />
                                                                    <FormikInput 
                                                                        name="contact.surname"
                                                                        label="Фамилия" 
                                                                        placeholder={isEditable ? "Фамилия" : ""}
                                                                        disabled={!isEditable}
                                                                        className="inputForm"
                                                                        $afterIcon="clearInput"
                                                                        maskConfig={{mask: /^[A-Za-zА-Яа-яЁё\s]*$/}}
                                                                    />
                                                                    <FormikInput 
                                                                        name="contact.patronymic"
                                                                        label="Отчество" 
                                                                        placeholder={isEditable ? "Отчество" : ""}
                                                                        disabled={!isEditable}
                                                                        className="inputForm"
                                                                        $afterIcon="clearInput"
                                                                        maskConfig={{mask: /^[A-Za-zА-Яа-яЁё\s]*$/}}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </WrapperFormProfile>
                                                    )}
                                                    <WrapperFormProfile>
                                                        <div className="title">Общие данные</div>
                                                        <div className="container">
                                                            <div className="row">
                                                                <FormikInput
                                                                    name="phone"
                                                                    label="Телефон" 
                                                                    placeholder={isEditable ? "Телефон" : ""}
                                                                    className="inputForm"
                                                                    disabled={!isEditable}
                                                                    $afterIcon="clearInput"
                                                                    maskConfig={{mask: '+{7}-000-000-00-00'}}
                                                                />
                                                                <FormikInput 
                                                                    name="email"
                                                                    label="Почта" 
                                                                    placeholder={isEditable ? "Почта" : ""}
                                                                    disabled={!isEditable}
                                                                    className="inputForm"
                                                                    $afterIcon="clearInput"
                                                                    maskConfig={{ mask: /^\S*@?\S*$/}}
                                                                />
                                                                <FormikInput 
                                                                    name="created_at"
                                                                    label="Дата регистрации" 
                                                                    placeholder={isEditable ? "Дата регистрации" : ""}
                                                                    className="inputForm"
                                                                    disabled={true}
                                                                    $afterIcon="clearInput"
                                                                />
                                                                <FormikInput 
                                                                    name="discount"
                                                                    label="Постоянная скидка (%)" 
                                                                    placeholder={isEditable ? "Постоянная скидка (%)" : ""}
                                                                    className="inputForm"
                                                                    disabled={!isEditable}
                                                                    $afterIcon="clearInput"
                                                                    maskConfig={{
                                                                        mask: Number,
                                                                        min: 0,
                                                                        max: 100,
                                                                    }}
                                                                />
                                                            </div>
                                                            {data.is_black_list && (
                                                                <div className="row">
                                                                    <FormikInput
                                                                        name="comment"
                                                                        label="Комментарий" 
                                                                        placeholder={isEditable ? "Комментарий" : ""}
                                                                        className="inputForm"
                                                                        disabled={!isEditable}
                                                                        $afterIcon="clearInput"
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </WrapperFormProfile>
                                                    <Certificates counterparty_id={id} />
                                                </Tab>
                                                <Tab label="Документы">
                                                    <>
                                                        <WrapperFormProfile>
                                                            <div className="wrapperSerteficateHeader">
                                                                <div className="title">Документы</div>
                                                            </div>
                                                            <div className="container">
                                                                <div className="row">
                                                                    <FormikInput 
                                                                        name="additional_data.documents.serial"
                                                                        label="Серия и номер" 
                                                                        placeholder={isEditable ? "Серия и номер" : ""}
                                                                        className="inputForm"
                                                                        disabled={!isEditable}
                                                                        maskConfig={{ mask: '0000 000000' }}
                                                                    />
                                                                    <FormikInput 
                                                                        name="additional_data.documents.issue_date"
                                                                        label="Дата выдачи" 
                                                                        placeholder={isEditable ? "Дата выдачи" : ""}
                                                                        className="inputForm"
                                                                        disabled={!isEditable}
                                                                        maskConfig={{
                                                                            mask: Date,
                                                                            pattern: 'd{.}`m{.}`Y',
                                                                            blocks: {
                                                                                d: {
                                                                                    mask: IMask.MaskedRange,
                                                                                    from: 1,
                                                                                    to: 31,
                                                                                    maxLength: 2,
                                                                                },
                                                                                m: {
                                                                                    mask: IMask.MaskedRange,
                                                                                    from: 1,
                                                                                    to: 12,
                                                                                    maxLength: 2,
                                                                                },
                                                                                Y: {
                                                                                    mask: IMask.MaskedRange,
                                                                                    from: 1945,
                                                                                    to: moment().format("YYYY"),
                                                                                    maxLength: 4,
                                                                                },
                                                                            }
                                                                        }}
                                                                    />
                                                                    <FormikInput 
                                                                        name="additional_data.documents.division_code"
                                                                        label="Код подразделения" 
                                                                        placeholder={isEditable ? "Код подразделения" : ""}
                                                                        className="inputForm"
                                                                        disabled={!isEditable}
                                                                        maskConfig={{ mask: '000-000' }}
                                                                    />
                                                                    <FormikInput 
                                                                        name="additional_data.documents.issued_by"
                                                                        label="Кем выдан" 
                                                                        placeholder={isEditable ? "Кем выдан" : ""}
                                                                        className="inputForm"
                                                                        disabled={!isEditable}
                                                                        maskConfig={{ mask: /^[A-Za-zА-Яа-яЁё\s]*$/ }}
                                                                    />
                                                                </div>
                                                                <div className="row">
                                                                    <UploadFile 
                                                                        file={data?.document_photos ? data?.document_photos as any : []} 
                                                                        disabled={isEditable}
                                                                        onNewFilesChange={setNewFiles}
                                                                        onRemovedFilesChange={setRemovedFiles}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </WrapperFormProfile>
                                                        <WrapperFormProfile>
                                                            <div className="wrapperSerteficateHeader">
                                                                <div className="title">Место прописки</div>
                                                            </div>
                                                            <div className="container">
                                                                <div className="row">
                                                                    <FormikInput 
                                                                        name="additional_data.documents.city"
                                                                        label="Город" 
                                                                        placeholder={isEditable ? "Город" : ""}
                                                                        disabled={!isEditable}
                                                                        className="inputForm"
                                                                        maskConfig={{ mask: /^[A-Za-zА-Яа-яЁё\s]*$/ }}
                                                                    />
                                                                    <FormikInput 
                                                                        name="additional_data.documents.street"
                                                                        label="Улица" 
                                                                        placeholder={isEditable ? "Улица" : ""}
                                                                        disabled={!isEditable}
                                                                        className="inputForm"
                                                                        maskConfig={{ mask: /^[A-Za-zА-Яа-яЁё\s]*$/ }}
                                                                    />
                                                                    <FormikInput 
                                                                        name="additional_data.documents.house_number"
                                                                        label="Дом" 
                                                                        placeholder={isEditable ? "Дом" : ""}
                                                                        disabled={!isEditable}
                                                                        className="inputForm"
                                                                    />
                                                                    <FormikInput 
                                                                        name="additional_data.documents.apartment_number"
                                                                        label="Квартира" 
                                                                        placeholder={isEditable ? "Квартира" : ""}
                                                                        disabled={!isEditable}
                                                                        className="inputForm"
                                                                        maskConfig={{
                                                                            mask: Number
                                                                        }}
                                                                    />
                                                                    <FormikInput 
                                                                        name="additional_data.documents.index"
                                                                        label="Индекс" 
                                                                        placeholder={isEditable ? "Индекс" : ""}
                                                                        disabled={!isEditable}
                                                                        className="inputForm"
                                                                        maskConfig={{
                                                                            mask: Number
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </WrapperFormProfile>
                                                    </>
                                                </Tab>
                                                <Tab label="История заказов">
                                                    {id && <OrdersTable id={id} />}
                                                </Tab>
                                            </Tabs>
                                        </TabsWrapper>
                                    </Form>
                                )
                            }}
                        </Formik>
                    }
                    {(isSuccess && data?.type === 1) &&
                        <Formik
                            key="companyForm"
                            initialValues={initialValuesCompany}
                            onSubmit={async(values) => {
                                const { uploadedPhotoInfo } = await handleFileUpload(photo, []);
                                const updatedValues = {
                                    ...getChangedValues(initialValuesCompany, values),
                                };
                                if (uploadedPhotoInfo) {
                                    updatedValues.profile_photo_hash_name = uploadedPhotoInfo.hash_name;
                                }
                                if (removedFiles.length > 0) {
                                    updatedValues.removed_document_photos_hash_names = removedFiles.map((file) => ({
                                        hash_name: file.hash_name,
                                    }));
                                }
                                if (newFiles.length > 0) {
                                    updatedValues.new_document_photos_hash_names = newFiles.map((file) => ({
                                        hash_name: file.hash_name,
                                    }));
                                }
                                handleEditProfile(
                                    updatedValues as any,
                                    refetch,
                                    setIsEditable,
                                    setEditButtonText,
                                    dispatch,
                                    id
                                )}
                            }
                            enableReinitialize
                        >
                            {({ submitForm }) => {
                                personalFormRef.current = submitForm;
                                return (
                                    <Form>
                                        <TabsWrapper>
                                            <Tabs>
                                                <Tab label="Профиль">
                                                    <WrapperAvatar>
                                                        {isEditable ? (
                                                            <UploadAvatar 
                                                                initialPhoto={currentPhoto}
                                                                onPhotoChange={handlePhotoChange}
                                                            />
                                                        ) : (
                                                            <Avatar photo={data.profile_photo?.read_url} name={data?.additional_data?.documents?.organization_name} jobs="Юридическое лицо" />
                                                            )
                                                        }
                                                    </WrapperAvatar>
                                                    <WrapperAvatar>
                                                    </WrapperAvatar>
                                                    <StyledLine margin="20px 0"/>
                                                    <WrapperFormProfile>
                                                        <div className="title">Общие данные</div>
                                                        <div className="container">
                                                            <div className="row">
                                                                <FormikInput
                                                                    name="additional_data.documents.organization_name"
                                                                    label="Наименование" 
                                                                    placeholder={isEditable ? "Наименование" : ""}
                                                                    className="inputForm"
                                                                    disabled={!isEditable}
                                                                />
                                                                <FormikInput 
                                                                    name="additional_data.documents.inn"
                                                                    label="ИНН" 
                                                                    placeholder={isEditable ? "ИНН" : ""}
                                                                    disabled={!isEditable}
                                                                    className="inputForm"
                                                                    maskConfig={{
                                                                        mask: Number,
                                                                    }}
                                                                />
                                                                <FormikInput 
                                                                    name="additional_data.documents.kpp"
                                                                    label="КПП" 
                                                                    placeholder={isEditable ? "КПП" : ""}
                                                                    className="inputForm"
                                                                    disabled={!isEditable}
                                                                    maskConfig={{
                                                                        mask: Number,
                                                                    }}
                                                                />
                                                                <FormikInput 
                                                                    name="created_at"
                                                                    label="Дата регистрации" 
                                                                    placeholder={isEditable ? "Дата регистрации" : ""}
                                                                    className="inputForm"
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                            <div className="row">
                                                                <FormikInput
                                                                    name="additional_data.documents.bank_name"
                                                                    label="Название банка" 
                                                                    placeholder={isEditable ? "Название банка" : ""}
                                                                    className="inputForm"
                                                                    disabled={!isEditable}
                                                                />
                                                                <FormikInput 
                                                                    name="additional_data.documents.bik"
                                                                    label="БИК банка"
                                                                    placeholder={isEditable ? "БИК банка" : ""}
                                                                    disabled={!isEditable}
                                                                    className="inputForm"
                                                                />
                                                                <FormikInput 
                                                                    name="additional_data.documents.checking_account"
                                                                    label="Расчетный счет" 
                                                                    placeholder={isEditable ? "Расчетный счет" : ""}
                                                                    className="inputForm"
                                                                    disabled={!isEditable}
                                                                />
                                                                <FormikInput 
                                                                    name="additional_data.documents.correspondent_account"
                                                                    label="Кореспонденческий счет" 
                                                                    placeholder={isEditable ? "Кореспонденческий счет" : ""}
                                                                    className="inputForm"
                                                                    disabled={!isEditable}
                                                                />
                                                            </div>
                                                            <div className="row">
                                                                <FormikInput
                                                                    name="phone"
                                                                    label="Телефон"
                                                                    placeholder={isEditable ? "Телефон" : ""}
                                                                    value=""
                                                                    className="inputForm"
                                                                    disabled={!isEditable}
                                                                />
                                                                <FormikInput 
                                                                    name="email"
                                                                    label="Почта" 
                                                                    placeholder={isEditable ? "Почта" : ""}
                                                                    disabled={!isEditable}
                                                                    className="inputForm"
                                                                />
                                                                <FormikInput 
                                                                    name="additional_data.documents.legal_address"
                                                                    label="Юридический адрес" 
                                                                    placeholder={isEditable ? "Юридический адрес" : ""}
                                                                    className="inputForm"
                                                                    disabled={!isEditable}
                                                                />
                                                                <FormikInput 
                                                                    name="discount"
                                                                    label="Постоянная скидка (%)" 
                                                                    placeholder={isEditable ? "Постоянная скидка (%)" : ""}
                                                                    className="inputForm"
                                                                    disabled={!isEditable}
                                                                    maskConfig={{
                                                                        mask: Number,
                                                                        min: 0,
                                                                        max: 100,
                                                                    }}
                                                                />
                                                            </div>
                                                            {data.is_black_list && (
                                                                <div className="row">
                                                                    <FormikInput
                                                                        name="comment"
                                                                        label="Комментарий" 
                                                                        placeholder={isEditable ? "Комментарий" : ""}
                                                                        className="inputForm"
                                                                        disabled={!isEditable}
                                                                        $afterIcon="clearInput"
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </WrapperFormProfile>
                                                    <WrapperFormProfile>
                                                        <div className="title">Контактное лицо</div>
                                                        <div className="container">
                                                            <div className="row">
                                                                <FormikInput
                                                                    name="contact.first_name"
                                                                    label="Имя" 
                                                                    placeholder={isEditable ? "Имя" : ""}
                                                                    className="inputForm"
                                                                    disabled={!isEditable}
                                                                />
                                                                <FormikInput
                                                                    name="contact.patronymic"
                                                                    label="Отчество" 
                                                                    placeholder={isEditable ? "Отчество" : ""}
                                                                    className="inputForm"
                                                                    disabled={!isEditable}
                                                                />
                                                                <FormikInput
                                                                    name="contact.surname"
                                                                    label="Фамилия" 
                                                                    placeholder={isEditable ? "Фамилия" : ""}
                                                                    className="inputForm"
                                                                    disabled={!isEditable}
                                                                />
                                                                <FormikInput 
                                                                    name="contact.phone"
                                                                    label="Телефон" 
                                                                    placeholder={isEditable ? "Телефон" : ""}
                                                                    disabled={!isEditable}
                                                                    className="inputForm"
                                                                />
                                                                <FormikInput 
                                                                    name="contact.email"
                                                                    label="Почта" 
                                                                    placeholder={isEditable ? "Почта" : ""}
                                                                    disabled={!isEditable}
                                                                    className="inputForm"
                                                                />
                                                            </div>
                                                        </div>
                                                    </WrapperFormProfile>
                                                    <Certificates counterparty_id={id} />
                                                </Tab>
                                                <Tab label="История заказов">
                                                    {id && <OrdersTable id={id} />}
                                                </Tab>
                                            </Tabs>
                                        </TabsWrapper>
                                    </Form>
                                )
                            }} 
                        </Formik>
                    }
                </>
            }
        </DashboardLayout>
    )
}