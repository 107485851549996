import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Moment } from 'moment'
import moment from 'moment/moment'

import { isAlreadyHaveEventWithThatTime } from 'features/BookingCalendarController/model/hooks/useCalendarActions'
import {
    ViewMode,
    ViewModeType,
} from 'features/BookingCalendarController/model'
import {
    Counterparty,
    CounterpartyType,
    useCounterpartyAsyncAutocompleteOptions,
} from 'entities/counterparties/model'
import { Area } from 'entities/areas/model'
import { Booking, useGetBookingsListQuery } from 'entities/bookings/model'
import { OrderBooking } from 'entities/orders/model'

import {
    selectActiveBookingId,
    selectBookingCounterpartyId,
    selectBookingCounterpartyName,
    selectBookingOrderId,
    selectBookings,
    selectTimeFilter,
    setCurrentCalendarEvents,
} from '../../../Booking/model/slices'
import { CalendarEvent } from '../../../ReactBigCalendar/ReactBigCalendar'

const createAlreadyCreatedBookingsEvents = (
    booking: Booking,
    currentFormOrderId: number | null,
    activeBookingId: number | null,
): CalendarEvent => {
    const counterpartyFullName = booking.counterparty.fullName.split(' ')

    const surname = counterpartyFullName?.[1]
    const firstName = counterpartyFullName?.[0]
    const patronymic = counterpartyFullName?.[2]

    const counterpartyName = [
        surname ?? '',
        firstName ? firstName.split('')[0] + '.' : '',
        patronymic ? patronymic.split('')[0] + '.' : '',
    ].join(' ')

    return {
        id: booking.id,
        comment: booking?.comment || null,
        orderId: booking.orderId,
        title: <div>{booking.area.name}</div>,
        counterpartyName,
        counterpartyPhone: booking.counterparty.phone,
        isCompany: booking.counterparty.type === CounterpartyType.Company,
        start: booking.startTime ?? new Date(),
        end: booking.endTime ?? new Date(),
        resourceId: booking.area.id,
        resourceName: booking.area.name ?? '',
        isNew: false,
        isEdit: activeBookingId
            ? activeBookingId === booking.id
            : currentFormOrderId === booking.orderId,
    }
}

const createCurrentFormBookingsEvents = (
    booking: OrderBooking,
    currentFormOrderId: number | null,
    activeBookingId: number | null,
    isNew: boolean,
    currentFormCounterparty?: Counterparty,
): CalendarEvent => {
    const contact = currentFormCounterparty?.contact
    const isCompany = currentFormCounterparty?.type === CounterpartyType.Company

    const personName = contact
        ? [
              contact?.surname ?? '',
              contact?.firstName ? contact.firstName.split('')[0] + '.' : '',
              contact?.patronymic ? contact.patronymic.split('')[0] + '.' : '',
          ].join(' ')
        : null
    const companyName =
        currentFormCounterparty?.additionalData?.documents?.organizationName ??
        ''
    const counterpartyName = isCompany ? companyName : personName

    return {
        id: booking?.id,
        comment: booking?.comment || null,
        orderId: currentFormOrderId,
        title: <div>{booking?.area?.name}</div>,
        counterpartyName,
        counterpartyPhone: currentFormCounterparty?.phone ?? null,
        isCompany,
        start: booking.startTime ?? new Date(),
        end: booking.endTime ?? new Date(),
        resourceId: booking.area?.id ?? 0,
        resourceName: booking?.area?.name ?? '',
        isNew,
        isEdit: activeBookingId === booking.id,
    }
}

export const useBuildCalendarEvents = (
    currentDate: Moment,
    view: ViewModeType,
    areas: Area[],
) => {
    const dispatch = useDispatch()

    const timeFilter = useSelector(selectTimeFilter)
    const currentFormCounterpartyId = useSelector(selectBookingCounterpartyId)
    const currentFormCounterpartyName = useSelector(
        selectBookingCounterpartyName,
    )

    const { counterparties } = useCounterpartyAsyncAutocompleteOptions({
        search_param: currentFormCounterpartyName ?? undefined,
    })
    const currentFormOrderId = useSelector(selectBookingOrderId)
    const currentFormCounterparty = counterparties?.find(
        counterparty => counterparty.id === currentFormCounterpartyId,
    )

    const activeBookingId = useSelector(selectActiveBookingId)
    const { bookings: alreadyCreatedBookings } =
        useGetBookingsListQuery(timeFilter)
    const alreadyCreatedBookingsEvents = useMemo(
        () =>
            alreadyCreatedBookings.map(booking =>
                createAlreadyCreatedBookingsEvents(
                    booking,
                    currentFormOrderId,
                    activeBookingId,
                ),
            ),
        [alreadyCreatedBookings, activeBookingId, currentFormOrderId],
    )

    const currentFormBookings = useSelector(selectBookings)
    const currentFormBookingsEvents = useMemo(
        () =>
            currentFormBookings.map(booking =>
                createCurrentFormBookingsEvents(
                    booking,
                    currentFormOrderId,
                    activeBookingId,
                    !alreadyCreatedBookings.find(bkg => bkg.id === booking.id),
                    currentFormCounterparty,
                ),
            ),
        [
            alreadyCreatedBookings,
            currentFormBookings,
            currentFormOrderId,
            currentFormCounterparty,
            activeBookingId,
        ],
    )

    const bookingEvents = useMemo(
        () =>
            Array.from(
                new Map(
                    [
                        ...alreadyCreatedBookingsEvents,
                        ...currentFormBookingsEvents,
                    ].map(event => [event.id, event]),
                ).values(),
            ),
        [alreadyCreatedBookingsEvents, currentFormBookingsEvents],
    )

    useEffect(() => {
        dispatch(setCurrentCalendarEvents({ events: bookingEvents }))
    }, [bookingEvents])

    const bookingUnavailableEvents = useMemo(() => {
        const result: CalendarEvent[] = []

        if (view !== ViewMode.Day) {
            return []
        }

        areas.forEach(area => {
            const availableTime = area.adBookingInfo?.availableBookingTime?.[0]
            if (!availableTime) {
                return false
            }

            if (!availableTime.start || !availableTime.end) {
                return false
            }
            const availableTimeStart = moment(
                `${currentDate.format('YYYY-MM-DD')} ${availableTime.start}`,
                'YYYY-MM-DD HH:mm:ss',
            )
            const availableTimeEnd = moment(
                `${currentDate.format('YYYY-MM-DD')} ${availableTime.end}`,
                'YYYY-MM-DD HH:mm:ss',
            )

            const startOFDay = moment(currentDate.startOf('day').toDate())
            const endOFDay = moment(currentDate.endOf('day').toDate())

            const startEvent = {
                id: -1,
                comment: '',
                orderId: -1,
                title: 'Бронь недоступна',
                counterpartyName: '',
                counterpartyPhone: '',
                isCompany: false,
                resourceId: area.id,
                resourceName: area.name,
                isNew: false,
                isEdit: false,
                isBlocked: true,
                start: startOFDay.toDate(),
                end: availableTimeStart.toDate(),
            }

            if (
                !(
                    isAlreadyHaveEventWithThatTime(
                        startOFDay.toDate(),
                        availableTimeStart.toDate(),
                        area.id,
                        bookingEvents,
                    ) && moment().subtract(1, 'day').isBefore(startOFDay)
                )
            ) {
                result.push(startEvent)
            }

            const endEvent = {
                id: -1,
                comment: '',
                orderId: -1,
                title: 'Бронь недоступна',
                counterpartyName: '',
                counterpartyPhone: '',
                isCompany: false,
                resourceId: area.id,
                resourceName: area.name,
                isNew: false,
                isEdit: false,
                isBlocked: true,
                start: availableTimeEnd.toDate(),
                end: endOFDay.toDate(),
            }
            if (
                !(
                    isAlreadyHaveEventWithThatTime(
                        availableTimeEnd.toDate(),
                        endOFDay.toDate(),
                        area.id,
                        bookingEvents,
                    ) && moment().subtract(1, 'day').isBefore(availableTimeEnd)
                )
            ) {
                result.push(endEvent)
            }
        })
        return result
    }, [currentDate, view, areas])

    return {
        backgroundEvents: bookingUnavailableEvents,
        alreadyCreatedBookingsEvents,
        currentFormBookingsEvents,
        bookingEvents,
    }
}
