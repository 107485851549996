import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
} from 'react-router-dom'

import { Providers } from './providers'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SignInPages } from 'pages/sign-in'
import { PrivateRoute } from './routers/privateRouter'
import { Dashboard } from 'pages/dashboard'
import { Provider } from 'react-redux'
import { store } from 'shared/redux/store'
import { useEffect } from 'react'
import { Clients } from 'pages/clients'
import { CreateProfile } from 'pages/clientsCreate'
import { Profile } from 'pages/clientsProfile'
import { Booking } from 'pages/booking'
import { checkAuth } from 'features/auth/model/authSlice'
import { Finance } from 'pages/finance'
import { Archive } from 'pages/archive'
import { CalendarDayPage } from 'pages/calendarDay'
import { CalendarWeekPage } from 'pages/calendarWeek'
import { CalendarMonthPage } from 'pages/calendarMonth'
import { EmployeesList } from 'pages/EmployeesList'
import { EmployeesProfilePages } from 'pages/EmployeesProfile'
import { EmployeesCreatePages } from 'pages/EmployeesCreate/EmployeesCreate'
import { NomenclatureList } from 'pages/NomenclatureList'
import { NotFound } from 'pages/notFound'
import {
    AREASCREATE,
    AREASLIST,
    AREASPROFILE,
    CALENDAR,
    CALENDARDAY,
    CALENDARMONTH,
    CALENDARWEEK,
    CLIENTSCREATE,
    CLIENTSLIST,
    CLIENTSPROFILE,
    EMPLOYEECREATE,
    EMPLOYEESLIST,
    EMPLOYEESPROFILE,
    MANAGEMENT,
    NOMENCLATURECREATE,
    NOMENCLATURELIST,
    NOMENCLATUREPROFILE,
    SETTING,
    SETTINGBOOKINGS,
    SETTINGNOTIFICATION,
    SETTINGORG,
    SETTINGORGCREATE,
    SETTINGORGPROFILE,
    SETTINGPROFILE,
    SETTINGROLE,
    SETTINGTHEME,
} from 'shared/consts/routingConsts'
import { NomenclatureCreatePages } from 'pages/NomenclatureCreate'
import { NomenclatureProfilePages } from 'pages/NomenclatureProfile'
import { SettingPages } from 'pages/setting'
import { AreasListPages } from 'pages/areasList'
import { AreasProfilePages } from 'pages/areasProfile'
import { AreasCreatePages } from 'pages/areasCreate'
import { Fallback } from 'shared/components/Fallback'
import { SettingProfilePages } from 'pages/SettingProfile'
import { SettingThemePages } from 'pages/SettingTheme'
import { SettingNotificationsPages } from 'pages/SettingNotifications'
import { SettingRolePages } from 'pages/SettingRole'
import { InvoicePage } from 'pages/invoice'

import 'rsuite/DatePicker/styles/index.css'
import { SettingOrgPages } from 'pages/SettingOrg'
import { SettingOrgCreatePages } from 'pages/SettingOrgCreate'
import { SettingOrgProfilePages } from 'pages/SettingOrgProfile'
import { SettingBookingsPages } from 'pages/SettingBookings'
import { CalendarPages } from 'pages/calendar'
import { ManagementPages } from 'pages/management'

const routes = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path='/auth/sign-in' element={<SignInPages />} />
            <Route
                path='/'
                element={<PrivateRoute />}
                errorElement={<Fallback />}
            >
                <Route path='/' element={<Dashboard />} />

                {/* Календарь */}
                <Route path={CALENDAR} element={<CalendarPages />}>
                    <Route index element={<CalendarDayPage />} />
                    <Route path={CALENDARDAY} element={<CalendarDayPage />} />
                    <Route path={CALENDARWEEK} element={<CalendarWeekPage />} />
                    <Route path={CALENDARMONTH} element={<CalendarMonthPage />} />
                </Route>

                {/* Клиенты */}
                <Route path={CLIENTSLIST} element={<Clients />} />
                <Route path={CLIENTSCREATE} element={<CreateProfile />} />
                <Route path={`${CLIENTSPROFILE}/:id`} element={<Profile />} />
                <Route path={`/booking/:id`} element={<Booking />} />

                {/* Залы | Обрудование | Сотрудники */}
                <Route path={MANAGEMENT} element={<ManagementPages />}>
                    <Route index element={<AreasListPages />} />
                    <Route path={AREASLIST} element={<AreasListPages />} />
                    <Route path={`${AREASPROFILE}/:id`}element={<AreasProfilePages />} />
                    <Route path={NOMENCLATURELIST} element={<NomenclatureList />} />
                    <Route path={NOMENCLATURECREATE} element={<NomenclatureCreatePages />} />
                    <Route path={`${NOMENCLATUREPROFILE}/:id`} element={<NomenclatureProfilePages />} />
                    <Route path={AREASCREATE} element={<AreasCreatePages />} />
                    <Route path={EMPLOYEESLIST} element={<EmployeesList />} />
                    <Route path={EMPLOYEECREATE} element={<EmployeesCreatePages />} />
                    <Route path={`${EMPLOYEESPROFILE}/:id`} element={<EmployeesProfilePages />} />
                </Route>

                {/* Финансы и */}
                <Route path='/finance' element={<Finance />} />

                {/* Настройки */}
                <Route path={SETTING} element={<SettingPages />}>
                    <Route index element={<SettingProfilePages />} />
                    <Route
                        path={SETTINGPROFILE}
                        element={<SettingProfilePages />}
                    />
                    <Route
                        path={SETTINGTHEME}
                        element={<SettingThemePages />}
                    />
                    <Route
                        path={SETTINGNOTIFICATION}
                        element={<SettingNotificationsPages />}
                    />
                    <Route path={SETTINGBOOKINGS} element={<SettingBookingsPages />} />
                    <Route path={SETTINGROLE} element={<SettingRolePages />} />
                    <Route path={SETTINGORG} element={<SettingOrgPages />} />
                </Route>

                <Route path={SETTINGORGCREATE} element={<SettingOrgCreatePages />} />
                <Route path={`${SETTINGORGPROFILE}/:id`} element={<SettingOrgProfilePages />} />

                {/* Архив */}
                <Route path='/archive' element={<Archive />} />

                {/* Payments */}
                <Route path='/invoice/:id' element={<InvoicePage />} />
            </Route>
            <Route path='*' element={<NotFound />} />
        </>,
    ),
)

function App() {
    const queryClient = new QueryClient()
    return (
        <QueryClientProvider client={queryClient}>
            <Providers>
                <RouterProvider
                    fallbackElement={<Fallback />}
                    router={routes}
                />
            </Providers>
        </QueryClientProvider>
    )
}

const AppWrapper = () => {
    useEffect(() => {
        // if(localStorage.getItem('token')) {
            store.dispatch(checkAuth())
        // }
    }, [])

    return (
        <Provider store={store}>
            <App />
        </Provider>
    )
}

export default AppWrapper
