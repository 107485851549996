import { Accordion } from "shared/components/Accordion"
import { AccordionContent, SettingRow, WrapperSettingRole } from "./style"
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import { getRoles } from "../api/getRoles";
import { Loader } from "shared/components/Loader";
import { Button } from "shared/components/Button";
import { Icon } from "shared/components/Icons";
import { useEffect, useRef, useState } from "react";
import { IPropsPermissions, IPropsRoleRow } from "../types";
import { Formik, FormikHelpers } from "formik";
import { FormikInput } from "shared/components/FormikInput";
import { validateSchemaRoles } from "../model/validateSchema";
import { axiosApi } from "shared/api/axios";
import { showNotification } from "shared/redux/slice/notificationSlice";
import { useDispatch } from "react-redux";
import { PermissionsBlock } from "./PermissionsBlock";

export const SettingRole = () => {
    
    const formikRef = useRef<FormikHelpers<any>>(null);
    
    const [newRole, setNewRole] = useState<IPropsRoleRow[]>([]);
    const [permissions, setPermissions] = useState<IPropsPermissions | null>(null);
    
    const dispatch = useDispatch();
    
    const { isLoading, data, refetch } = useQuery({
        queryKey: ["getRoles"], 
        queryFn: () => {
            return getRoles();
        },
        placeholderData: keepPreviousData,
        retry: 3,
    });

    const addNewRole = () => {
        setNewRole([
            ...newRole,
            {
                name: ""
            }
        ])
    }

    const handleSaveClick = (e: any) => {
        if (formikRef.current) {
            formikRef.current.submitForm();
            e.preventDefault();
        }
    }

    const mutation = useMutation({
        mutationFn: (values: IPropsRoleRow) => {
            return axiosApi.post(`${process.env.API_BACKEND}/v1/roles/role/`, values)
        },
        onError: () => {
            dispatch(showNotification({
                message: "Ошибка создания роли!",
                type: "error",
            }));
        },
        onSuccess: () => {
            dispatch(showNotification({
                message: "Роль успешно создана",
                type: "success",
            }));
            refetch();
            setNewRole([]);
        }
    })

    const handleSubmit = (values: IPropsRoleRow) => {
        mutation.mutate(values)
    }

    const OpenSingleRoles = (id:number) => {
        console.log(id);
    }

    useEffect(() => {
        if(!permissions) {
            axiosApi.get<IPropsPermissions>(`${process.env.API_BACKEND}/v1/permissions/permission/`).then((data) => {
                setPermissions(data.data);
            })
        }
    }, [])

    if(isLoading) {
        return (
            <Loader />
        )
    }

    return (
        <WrapperSettingRole>
            <div className="title">Роли</div>
            {!isLoading && data && (
                <SettingRow>
                    {data.roles.map((item, index) => (
                        <Accordion
                            key={index}
                            isOpen={false}
                            title={item.name}
                            className="accordion__setting"
                            handleOpen={() => OpenSingleRoles(item.id)}
                        >
                            {permissions &&
                                <PermissionsBlock
                                    id={item.id}
                                    permissions={permissions}
                                    name={item.name}
                                />
                            }
                        </Accordion>
                    ))}
                    <div className="rowCreateRole">
                        <div className="rowCreateRole__header">
                            <div className="title">Создать новую роль</div>
                            {newRole.length === 0 ? 
                                <Button onClick={addNewRole} className="rowCreateRole-btn">
                                    <Icon name="addRoundedIcon" />
                                </Button>
                                : 
                                <div className="rowCreateRole__btns">
                                    <Button onClick={() => setNewRole([])} themeType="outline-2" bgLoading="white" className="rowCreateRole-btn delete">
                                        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.34315 0.667596L12.6569 11.9813M12.6569 0.667596L1.34315 11.9813" stroke="#ED4627" strokeLinecap="round"/>
                                        </svg>
                                    </Button>
                                    <Button loading={mutation.isPending} onClick={handleSaveClick} bgLoading="white" className="rowCreateRole-btn">
                                        <Icon name="checkTwo" />
                                    </Button>
                                </div>
                            }
                        </div>
                        {newRole.map((item: IPropsRoleRow, index: number) => (
                            <Formik
                                innerRef={formikRef as any}
                                key="roles"
                                initialValues={{
                                    name: ""
                                }}
                                onSubmit={handleSubmit}
                                validationSchema={validateSchemaRoles}
                            >
                                <div key={index} className="accordion__setting newRole__setting">
                                    <AccordionContent>
                                        <div className="subtitle">Название</div>
                                        <FormikInput
                                            name="name" 
                                            placeholder="Напишите название роли"
                                        />
                                        {/* {permissions &&
                                            <PermissionsBlock
                                                permissions={permissions}
                                            />
                                        } */}
                                    </AccordionContent>
                                </div>
                            </Formik>
                        ))}
                    </div>
                </SettingRow>
            )}
        </WrapperSettingRole>
    )
}