import { useMemo } from 'react'
import { SlotInfo } from 'react-big-calendar'
import { useDispatch, useSelector } from 'react-redux'
import { EventInteractionArgs } from 'react-big-calendar/lib/addons/dragAndDrop'
import { useNavigate } from 'react-router-dom'
import { debounce } from 'lodash'
import moment from 'moment'

import { useAreasResourcesQuery } from 'entities/areas/model/hooks/useAreasResourcesQuery'
import { useGetBookingsListQuery } from 'entities/bookings/model'
import { Area } from 'entities/areas/model'
import { BookingsApi } from 'entities/bookings/api'

import { showNotification } from 'shared/redux/slice/notificationSlice'
import { closeSidebar, openSidebar, SideBar } from 'shared/redux/slice/sidebar'

import {
    ActiveBookingFormTab,
    addBooking,
    BookingMode,
    clearBookingState,
    editBooking,
    OrderViewMode,
    removeBooking,
    selectActiveBookingId,
    selectBookingFormOrderViewMode,
    selectBookingIdToEdit,
    selectBookingMode,
    selectBookingOrderId,
    selectBookings,
    selectOrderEditStateInitialising,
    selectTimeFilter,
    setActiveBookingId,
    setBookingActiveTab,
    setBookingFormMode,
    setBookingFormOrderViewMode,
    setBookingIdToEdit,
    setBookingOrderId,
    setToday,
} from '../../../Booking/model/slices'
import { CalendarEvent } from '../../../ReactBigCalendar/ReactBigCalendar'
import { useFillStore } from '../../../Booking/model/hooks'
import { ViewMode, ViewModeType } from './useCalendarController'

export const isAlreadyHaveBookingWithThatHour = (
    hour: number,
    resourceId: number | null,
    bookings: CalendarEvent[],
    bookingId?: number | null,
    isStart?: boolean,
    isEnd?: boolean,
) => {
    return bookings.some(event => {
        const eventStart = moment(event.start!)
        const eventEnd = moment(event.end!)

        if (bookingId && event.id === bookingId) {
            return false
        }

        if (event.resourceId !== resourceId) {
            return false
        }

        const hourDate = eventStart.clone().set({
            hour,
            minute: 0,
            second: 0,
        })

        if (isStart) {
            return (
                hourDate.isSameOrAfter(eventStart) &&
                hourDate.isBefore(eventEnd)
            )
        }

        if (isEnd) {
            return (
                hourDate.isAfter(eventStart) &&
                hourDate.isSameOrBefore(eventEnd)
            )
        }

        return false
    })
}

export const isAlreadyHaveEventWithThatTime = (
    startTime: Date,
    endTime: Date,
    resourceId: number | null,
    bookingEvents: CalendarEvent[],
    bookingId?: number | null,
) => {
    const start = moment(startTime)
    const end = moment(endTime)
    return bookingEvents.some(event => {
        const eventStart = moment(event.start!)
        const eventEnd = moment(event.end!)

        if (bookingId && event.id === bookingId) {
            return false
        }

        if (event.resourceId !== resourceId) {
            return false
        }

        return start.isBefore(eventEnd) && end.isAfter(eventStart)
    })
}

export const isTimeUnavailableByArea = (
    startTime: Date,
    endTime: Date,
    resourceId: number | null,
    areas: Area[],
) => {
    const start = moment(startTime)
    const end = moment(endTime)
    return areas.find(area => {
        if (resourceId !== area.id) {
            return false
        }

        const availableTime = area.adBookingInfo?.availableBookingTime?.[0]

        if (!availableTime) {
            return false
        }

        if (!availableTime.start || !availableTime.end) {
            return false
        }
        const availableTimeStart = moment(
            `${moment(startTime).format('YYYY-MM-DD')} ${availableTime.start}`,
            'YYYY-MM-DD HH:mm:ss',
        )
        const availableTimeEnd = moment(
            `${moment(startTime).format('YYYY-MM-DD')} ${availableTime.end}`,
            'YYYY-MM-DD HH:mm:ss',
        )

        const isStartInvalid = !(
            start.isSameOrAfter(availableTimeStart) &&
            start.isSameOrBefore(availableTimeEnd)
        )
        const isEndInvalid = !(
            end.isSameOrAfter(availableTimeStart) &&
            end.isSameOrBefore(availableTimeEnd)
        )
        return isStartInvalid || isEndInvalid
    })
}

export const useCalendarActions = (
    view: ViewModeType,
    bookingEvents: CalendarEvent[],
    areas: Area[],
) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const activeOrderId = useSelector(selectBookingOrderId)

    const mode = useSelector(selectBookingMode)
    const isEditStateInitialising = useSelector(
        selectOrderEditStateInitialising,
    )

    const activeBookingOrderId = useSelector(selectBookingOrderId)
    const updateBookings = useFillStore()
    const { areasResources } = useAreasResourcesQuery()
    const timeFilter = useSelector(selectTimeFilter)

    const activeBookingId = useSelector(selectActiveBookingId)
    const bookings = useSelector(selectBookings)
    const activeBooking = useMemo(
        () => bookings.find(a => a.id === activeBookingId),
        [bookings, activeBookingId],
    )

    const previewBookingId = useSelector(selectBookingIdToEdit)
    const orderViewMode = useSelector(selectBookingFormOrderViewMode)
    const isEdit = useMemo(() => mode === BookingMode.Edit, [mode])
    const isPreview = useMemo(
        () =>
            isEdit &&
            orderViewMode === OrderViewMode.Preview &&
            !!previewBookingId,
        [orderViewMode, isEdit, previewBookingId],
    )

    const { refetch: refetchAlreadyCreatedBookings } =
        useGetBookingsListQuery(timeFilter)

    const showAreaError = (area: Area) => {
        const availableTimeStartPeriodStart = moment(
            area.adBookingInfo?.availableBookingTime[0].start,
            'HH:mm:ss',
        )
        const availableTimeStartPeriodEnd = moment(
            area.adBookingInfo?.availableBookingTime[0].end,
            'HH:mm:ss',
        )

        if (availableTimeStartPeriodStart && availableTimeStartPeriodEnd) {
            dispatch(
                showNotification({
                    message: `Данный зал доступен для записи только с ${availableTimeStartPeriodStart.format('HH:mm')} по ${availableTimeStartPeriodEnd.format('HH:mm')}`,
                    type: 'error',
                }),
            )
        }
    }

    const onSelectSlot = debounce((props: SlotInfo) => {
        if (props.action === 'click') {
            if (activeBooking && isPreview) {
                dispatch(clearBookingState())
                dispatch(closeSidebar())
            }
            return
        }

        const startTime = new Date(props.start)
        const endTime =
            props.action === 'doubleClick'
                ? new Date(
                      new Date(props.start).setHours(startTime.getHours() + 1),
                  )
                : new Date(props.end)

        const areaId = props.resourceId ? Number(props.resourceId) : null
        const areaName = props?.resourceId
            ? (areasResources?.find(res => res.id === areaId)?.title ?? '')
            : null

        if (view !== 'month') {
            if (moment(startTime).isBefore(moment())) {
                return dispatch(
                    showNotification({
                        message: 'Неверное время бронирования',
                        type: 'error',
                    }),
                )
            }
        }

        const unavailableAreaTime = isTimeUnavailableByArea(
            startTime,
            endTime,
            areaId,
            areas,
        )

        if (unavailableAreaTime) {
            return showAreaError(unavailableAreaTime)
        }

        if (
            isAlreadyHaveEventWithThatTime(
                startTime,
                endTime,
                areaId,
                bookingEvents,
            )
        ) {
            return
        }
        const onAddBooking = () => {
            const bookingId = new Date().getMilliseconds() ** 2
            dispatch(
                addBooking({
                    id: bookingId,
                    areaPrice: 0,
                    comment: '',
                    nomenclatures: [],
                    areaAbsolutDiscount: null,
                    areaPercentDiscount: null,
                    areaTotalPrice: 0,
                    areaTotalPriceWithDiscount: 0,
                    nomenclaturePrice: 0,
                    nomenclaturePriceWithDiscount: 0,
                    totalPrice: 0,
                    totalPriceWithDiscount: 0,
                    membersCount: null,
                    area: {
                        id: areaId,
                        name: areaName ?? 'Новый зал',
                    },
                    startTime,
                    endTime,
                }),
            )
            dispatch(setActiveBookingId({ bookingId }))
            dispatch(openSidebar(SideBar.Booking))
            dispatch(setBookingActiveTab({ tab: ActiveBookingFormTab.Booking }))

            setTimeout(() => {
                const event = document.getElementsByClassName(
                    `rbc-event ${bookingId}`,
                )?.[0] as HTMLElement
                event?.focus()
            }, 0)
        }

        if (view === ViewMode.Month) {
            dispatch(setToday({ today: startTime.toISOString() }))
            onRedirectToDay()
            dispatch(openSidebar(SideBar.Booking))
            return
        }

        if (mode === BookingMode.Edit && isEditStateInitialising) {
            return
        }
        if (mode === BookingMode.Edit && isPreview) {
            dispatch(clearBookingState())
            onAddBooking()
            return
        }

        if (
            activeBooking &&
            !activeBooking?.area?.id &&
            (!activeBooking.startTime || !activeBooking.endTime)
        ) {
            dispatch(
                editBooking({
                    bookingId: activeBooking.id,
                    body: {
                        area: {
                            id: areaId,
                            name: areaName ?? '',
                        },
                        startTime,
                        endTime,
                    },
                }),
            )
            if (view === ViewMode.Month) {
                onRedirectToDay()
            }
            return
        }

        onAddBooking()
    }, 100)

    const updateBookingByCalendarAction = async (
        props: EventInteractionArgs<CalendarEvent>,
    ) => {
        if (mode === BookingMode.Edit && isPreview) {
            return
        }

        const event = props.event
        if (event.isBlocked) {
            return
        }

        const areaId = props.resourceId
            ? Number(props.resourceId)
            : event.resourceId
        const areaName = props?.resourceId
            ? (areasResources?.find(res => res.id === areaId)?.title ??
              '' ??
              event.resourceName)
            : event.resourceName
        const startTime = new Date(props.start)
        const endTime = new Date(props.end)
        const bookingId = event.id
        const orderId = event.orderId

        if (moment(startTime).isBefore(moment())) {
            return dispatch(
                showNotification({
                    message: 'Неверное время бронирования',
                    type: 'error',
                }),
            )
        }

        const unavailableAreaTime = isTimeUnavailableByArea(
            startTime,
            endTime,
            areaId,
            areas,
        )
        if (unavailableAreaTime) {
            return showAreaError(unavailableAreaTime)
        }

        if (
            isAlreadyHaveEventWithThatTime(
                startTime,
                endTime,
                areaId,
                bookingEvents,
                bookingId ?? null,
            )
        ) {
            return
        }

        if (bookingId) {
            dispatch(
                editBooking({
                    bookingId,
                    body: {
                        startTime,
                        endTime,
                        area: {
                            id: areaId,
                            name: areaName ?? '',
                        },
                    },
                }),
            )
        }

        if (!orderId || !bookingId || !areaId || event.isNew) {
            return
        }

        await BookingsApi.updateBookings(
            { orderId, bookingId },
            { startTime, endTime, areaId },
        )
        await updateBookings(orderId, bookingId)
        await refetchAlreadyCreatedBookings()
    }

    const onDropSlot = async (props: EventInteractionArgs<CalendarEvent>) => {
        await updateBookingByCalendarAction(props)
    }

    const onEventResize = async (
        props: EventInteractionArgs<CalendarEvent>,
    ) => {
        await updateBookingByCalendarAction(props)
    }

    const onClickEvent = (props: EventInteractionArgs<CalendarEvent>) => {
        const orderId = props.event.orderId
        const id = props.event.id
        const today = moment(props.event.start)

        if (props.event.isBlocked) {
            return
        }

        const handleEditBooking = () => {
            if (orderId && id && id !== activeBookingOrderId) {
                dispatch(clearBookingState())
                dispatch(setBookingIdToEdit({ bookingIdToEdit: id }))
                dispatch(setBookingOrderId({ orderId }))
                dispatch(setBookingFormMode({ mode: BookingMode.Edit }))

                if (activeOrderId === orderId && !isPreview) {
                    dispatch(
                        setBookingFormOrderViewMode({
                            orderViewMode: OrderViewMode.Edit,
                        }),
                    )
                }
                updateBookings(orderId, id).then()
            }
        }

        const setActiveBooking = () => {
            if (id) {
                dispatch(setActiveBookingId({ bookingId: id }))
            }
        }

        if (view === ViewMode.Month) {
            dispatch(openSidebar(SideBar.Booking))
            if (today) {
                dispatch(setToday({ today: today.toDate().toISOString() }))
            }
            setActiveBooking()
            if (!props.event.isNew) {
                handleEditBooking()
                onRedirectToDay()
                return
            }
            onRedirectToDay()
            return
        }

        if (activeBookingId === id) {
            return
        }

        dispatch(openSidebar(SideBar.Booking))
        setActiveBooking()

        if (!props.event.isNew) {
            return handleEditBooking()
        }
    }

    const onKeyPress = (event: CalendarEvent, e: KeyboardEvent) => {
        if (
            !isPreview &&
            event.isEdit &&
            event.isNew &&
            (e.code === 'Backspace' || e.code === 'Delete')
        ) {
            const bookingId = event.id

            if (!bookingId) {
                return
            }

            if (activeBookingId === bookingId) {
                dispatch(setActiveBookingId({ bookingId: null }))
            }
            dispatch(removeBooking({ bookingId }))

            const bookingsAfterDelete = bookings.filter(
                booking => booking.id !== bookingId,
            )
            if (!bookingsAfterDelete.length) {
                dispatch(clearBookingState())
                dispatch(closeSidebar())
            }
        }
    }

    const onRedirectToDay = () => {
        navigate('/calendar/day')
    }

    return {
        onKeyPress,
        onSelectSlot,
        onDropSlot,
        onEventResize,
        onClickEvent,
        onRedirectToDay,
    }
}
