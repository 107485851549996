import React from 'react'

import { Tab, Tabs } from 'shared/components/Tabs'
import { Accordion } from 'shared/components/Accordion'
import {
    Header,
    GenericInfoForm,
    BookingForm,
    PaymentForm,
    DocumentsForm,
} from './components'
import { BookingSidebarContainer } from './styles'
import { useBookingSidebarController } from '../model/hooks'
import { Footer } from './components/Footer'
import { useAccordionStateHelper } from 'features/Booking/model/hooks/useAccordionStateHelper'
import { LoaderPreview } from 'features/OrderPreview/ui'
import { RefundOrder } from 'features/Booking/ui/components/Refund/RefundOrder'

export const BookingSidebar = () => {
    const {
        isOrderExist,
        isCompany,
        isEditStateInitialising,
        onGoToPaymentRedirectUrl,
        activeTabIndex,
        disabledTabIndex,
        isOpen,
        isPreview,
        isEdit,
        isRefund,
        isPaid,
        isPastBooking,
        goFromPreviewToEdit,
        onChangeTab,
        handleCloseSidebar,
        onDeleteOrder,
        onRemoveBooking,
        onGoRefund,
    } = useBookingSidebarController()
    const { AccordionState, onGoToPayment } = useAccordionStateHelper(isPreview)

    return (
        <BookingSidebarContainer isexpanded={isOpen}>
            <div
                className='sibedar-top-row'
                style={{
                    height: '100%',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
            >
                <Header
                    isOrderExist={isOrderExist}
                    onGoToPayment={onGoToPayment}
                    onGoToPaymentRedirectUrl={onGoToPaymentRedirectUrl}
                    onRemoveBooking={onRemoveBooking}
                    isPreview={isPreview}
                    goFromPreviewToEdit={goFromPreviewToEdit}
                    onDeleteOrder={onDeleteOrder}
                    handleCloseSidebar={handleCloseSidebar}
                    onGoRefund={onGoRefund}
                    isPaid={isPaid}
                    isPastBooking={isPastBooking}
                />
                <div className='line'></div>
                {isEditStateInitialising && isCompany === null ? (
                    <LoaderPreview />
                ) : (
                    <>
                        <div style={{ flex: 1, width: '100%' }}>
                            {isRefund ? (
                                <RefundOrder />
                            ) : (
                                <Tabs
                                    activeTabIndex={activeTabIndex}
                                    disabledTabIndex={disabledTabIndex}
                                    onTabChange={onChangeTab}
                                >
                                    <Tab label='Физическое лицо'>
                                        <Accordion
                                            isOpen={
                                                AccordionState.generalInfo
                                                    .isOpen
                                            }
                                            title={
                                                AccordionState.generalInfo.title
                                            }
                                            isOpenByDefault={!isEdit}
                                            handleOpen={
                                                AccordionState.generalInfo
                                                    .handleOpen
                                            }
                                        >
                                            <GenericInfoForm
                                                isPreview={isPreview}
                                            />
                                        </Accordion>
                                        <Accordion
                                            isOpen={
                                                AccordionState.booking.isOpen
                                            }
                                            title={AccordionState.booking.title}
                                            disabled={
                                                AccordionState.booking
                                                    .isDisabled
                                            }
                                            handleOpen={
                                                AccordionState.booking
                                                    .handleOpen
                                            }
                                            isOpenByDefault={isEdit}
                                        >
                                            <BookingForm
                                                isPreview={isPreview}
                                            />
                                        </Accordion>
                                        <Accordion
                                            isOpen={
                                                AccordionState.payment.isOpen
                                            }
                                            title={AccordionState.payment.title}
                                            disabled={
                                                AccordionState.payment
                                                    .isDisabled
                                            }
                                            handleOpen={
                                                AccordionState.payment
                                                    .handleOpen
                                            }
                                        >
                                            <PaymentForm
                                                isPreview={isPreview}
                                            />
                                        </Accordion>
                                        <Accordion
                                            isOpen={
                                                AccordionState.documents.isOpen
                                            }
                                            title={
                                                AccordionState.documents.title
                                            }
                                            disabled={
                                                AccordionState.documents
                                                    .isDisabled
                                            }
                                            handleOpen={
                                                AccordionState.documents
                                                    .handleOpen
                                            }
                                        >
                                            <DocumentsForm
                                                isPreview={isPreview}
                                            />
                                        </Accordion>
                                    </Tab>
                                    <Tab label='Юридическое лицо'>
                                        <Accordion
                                            isOpen={
                                                AccordionState.generalInfo
                                                    .isOpen
                                            }
                                            title={
                                                AccordionState.generalInfo.title
                                            }
                                            isOpenByDefault={!isEdit}
                                            handleOpen={
                                                AccordionState.generalInfo
                                                    .handleOpen
                                            }
                                        >
                                            <GenericInfoForm
                                                isPreview={isPreview}
                                                isCompany
                                            />
                                        </Accordion>
                                        <Accordion
                                            isOpen={
                                                AccordionState.booking.isOpen
                                            }
                                            title={AccordionState.booking.title}
                                            isOpenByDefault={isEdit}
                                            disabled={
                                                AccordionState.booking
                                                    .isDisabled
                                            }
                                            handleOpen={
                                                AccordionState.booking
                                                    .handleOpen
                                            }
                                        >
                                            <BookingForm
                                                isPreview={isPreview}
                                            />
                                        </Accordion>
                                        <Accordion
                                            isOpen={
                                                AccordionState.payment.isOpen
                                            }
                                            title={AccordionState.payment.title}
                                            disabled={
                                                AccordionState.payment
                                                    .isDisabled
                                            }
                                            handleOpen={
                                                AccordionState.payment
                                                    .handleOpen
                                            }
                                        >
                                            <PaymentForm
                                                isPreview={isPreview}
                                            />
                                        </Accordion>
                                        <Accordion
                                            isOpen={
                                                AccordionState.documents.isOpen
                                            }
                                            title={
                                                AccordionState.documents.title
                                            }
                                            disabled={
                                                AccordionState.documents
                                                    .isDisabled
                                            }
                                            handleOpen={
                                                AccordionState.documents
                                                    .handleOpen
                                            }
                                        >
                                            <DocumentsForm
                                                isPreview={isPreview}
                                            />
                                        </Accordion>
                                    </Tab>
                                </Tabs>
                            )}
                        </div>
                        <Footer />
                    </>
                )}
            </div>
        </BookingSidebarContainer>
    )
}
