import 'react-big-calendar/lib/sass/styles.scss'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import React, { memo } from 'react'
import moment, { Moment } from 'moment'
import { Calendar, momentLocalizer, View } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'

import { WrapperCalendar } from './style'

export interface CalendarEvent {
    allDay?: boolean
    id?: number | null
    orderId: number | null
    counterpartyName: string | null
    counterpartyPhone: string | null
    comment: string | null
    isCompany: boolean
    title: React.ReactNode | null
    start: Date | null
    end: Date | null
    resourceId: number | null
    resourceName: string
    isNew: boolean
    isEdit: boolean
    isBlocked?: boolean
}

// TODO: fix types
interface IPropsReactBigCalendar {
    views: any
    defaultView: View
    min?: Date
    max?: Date
    currentDate: Moment
    onDateChange: (date: Moment) => void
    events?: CalendarEvent[] | undefined
    resources?: { id: number; title: string }[]
    components?: any
    onEventResize?: (props: any) => void
    onDropSlot?: (props: any) => void
    onSelectSlot?: (props: any) => void
    onClickEvent?: (props: any) => void
    onShowMore?: (props: any) => void
    onKeyPress?: (event: any, e: any) => void
    backgroundEvents?: CalendarEvent[] | undefined
}

const DnDCalendar = withDragAndDrop(Calendar)
const localizer = momentLocalizer(moment)

const tooltipStringGeneration = (event: any) =>
    `ФИО: ${event.counterpartyName}\nТелефон: ${event.counterpartyPhone}\nЗал: ${event.resourceName}\nНачало: ${moment(event.start).format('DD.MM.YYYY HH:mm')}\nКонец: ${moment(event.end).format('DD.MM.YYYY HH:mm')}`

const ReactBigCalendarComponent = ({
    views,
    defaultView,
    min,
    max,
    currentDate,
    onDateChange,
    events,
    resources,
    components,
    onDropSlot,
    onEventResize,
    onSelectSlot,
    onClickEvent,
    onShowMore,
    onKeyPress,
    backgroundEvents,
}: IPropsReactBigCalendar) => {
    const event = ({ event }: { event: CalendarEvent }) => {
        if (event.isBlocked) {
            return <span style={{ fontSize: 12 }}>{event.title ?? ''}</span>
        }

        if (defaultView === 'month') {
            if (event.resourceName) {
                return (
                    <span style={{ fontSize: 12 }}>
                        {event.resourceName ?? ''}
                    </span>
                )
            }
            return null
        }

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                    paddingTop: 10,
                }}
            >
                {event.counterpartyName ? (
                    <span style={{ fontSize: 12 }}>
                        {event.counterpartyName ?? ''}
                    </span>
                ) : (
                    <></>
                )}
                {event.counterpartyPhone ? (
                    <span style={{ fontSize: 12 }}>
                        {event.counterpartyPhone ?? ''}
                    </span>
                ) : (
                    <></>
                )}
                {event.comment && event.comment?.length ? (
                    <span style={{ fontSize: 12 }}>{event.comment ?? ''}</span>
                ) : (
                    <></>
                )}
            </div>
        )
    }

    const eventPropGetter = (event: any) => {
        if (event.isBlocked) {
            return {
                style: {
                    background: '#e1e4ea',
                    opacity: 0.75,
                    color: '#282a42',
                    border: 'none',
                    borderRadius: 0,
                },
                className: event.id,
                name: event.id,
            }
        }
        if (moment(event.start).isBefore(moment())) {
            return {
                style: {
                    background: '#e1e4ea',
                    color: '#282a42',
                    borderColor: 'transparent',
                    borderLeft: '3px solid #282a42',
                },
                className: event.id,
                name: event.id,
            }
        }
        if (event.isEdit) {
            return {
                style: {
                    background: '#fae1c1',
                    color: '#bd7234',
                    borderColor: 'transparent',
                    borderLeft: '3px solid #bd7234',
                },
                className: event.id,
                name: event.id,
            }
        }
        if (event.isNew) {
            return {
                style: {
                    background: '#e3e6f8',
                    color: '#4557ab',
                    borderColor: 'transparent',
                    borderLeft: '3px solid #4557ab',
                },
                className: event.id,
                name: event.id,
            }
        }
        return {
            style: {
                background: '#e3f8ef',
                color: '#34bd93',
                borderColor: 'transparent',
                borderLeft: '3px solid #34bd93',
            },
            className: event.id,
            name: event.id,
        }
    }

    const tooltipAccessor =
        defaultView === 'month' ? tooltipStringGeneration : null

    const eventTimeRangeFormat = (date: { start: Date; end: Date }) => {
        const end = moment(date.end)
        const endDateFormatted =
            end.hour() === 23 && end.minute() === 59
                ? end.add(1, 'minutes').format('HH:mm')
                : end.format('HH:mm')
        return `${moment(date.start).format('HH:mm')} - ${endDateFormatted}`
    }

    return (
        <WrapperCalendar>
            <DnDCalendar
                onKeyPressEvent={onKeyPress}
                onDragStart={onClickEvent}
                onEventResize={onEventResize}
                onEventDrop={onDropSlot}
                onSelectSlot={onSelectSlot}
                localizer={localizer}
                views={views}
                defaultView={defaultView}
                timeslots={1}
                step={60}
                min={min ?? moment().startOf('day').toDate()}
                max={max ?? moment().endOf('day').toDate()}
                date={currentDate.toDate()}
                onNavigate={date => onDateChange(moment(date))}
                toolbar={false}
                events={events}
                resources={resources}
                eventPropGetter={eventPropGetter}
                components={{
                    event,
                    ...components,
                }}
                tooltipAccessor={tooltipAccessor}
                enableAutoScroll={false}
                selectable
                onShowMore={onShowMore}
                messages={{
                    showMore: (total: number) => `еще ${total}`,
                }}
                formats={{
                    eventTimeRangeFormat,
                }}
                backgroundEvents={backgroundEvents}
            />
        </WrapperCalendar>
    )
}

export const ReactBigCalendar = memo(ReactBigCalendarComponent)
