import { axiosApi } from "shared/api/axios";
import { IPropsNotifications } from "../types";

export const getNotifications = async (): Promise<IPropsNotifications> => {
    try {
        const response = await axiosApi.get<IPropsNotifications>(`${process.env.API_BACKEND}/v1/settings/setting/`);
        const data = response.data;

        return data;
    } catch (err) {
        console.error(err);
        throw new Error("Ошибка получения профиля");
    }
};