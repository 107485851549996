import styled from "styled-components";
import { StyledButton } from "../Button/style";

interface IPropsStyledTable {
    column: number;
    columnwidths?: string[];
}

export const StyledTable = styled.div`
    width: 100%;
    height: 100%;
    .clientsTable {

    }
    &.equipmentTable {
        .wrapperCellTitle{
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
            color: #1C1D21;
        }
        .wrapperCellDesc {
            color: #8181A5;
            font-size: 14px;
            line-height: 21px;
        }
    }
    .notFound {
        color: #8181A5;
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        padding-top: 300px;
    }
`;

export const StyledTableHead = styled.div<IPropsStyledTable>`
    display: grid;
    grid-template-columns: ${props =>
    props.columnwidths
        ? props.columnwidths.map(width => (width ? width : '1fr')).join(' ')
        : 'repeat(' + props.column + ', 1fr)'};
    padding: 10px 20px;
    background: #F4F7FF;
    border-radius: 8px;
    .styledTableHeadColumn {
        cursor: pointer;
        color: #8181A5;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; 
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        background: none;
        border: none;
    }
`;

export const StyledTableBody = styled.div<IPropsStyledTable>`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    .row {
        display: grid;
        grid-template-columns: ${props =>
        props.columnwidths
            ? props.columnwidths.map(width => (width ? width : '1fr')).join(' ')
            : 'repeat(' + props.column + ', 1fr)'};
        border-radius: 8px;
        border: 1px solid #E1E4EA;
        padding: 0px 20px;
        height: 81px;
        .bodyCell {
            display: grid;
            width: 100%;
            align-items: center;
            padding: 0 10px;
            .crop {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 155px;
            }
            .bodyCellImg {
                width: 48px;
                height: 48px;
                border-radius: 50px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .bodyCellImgBg {
                    background-color: #E1E4EA;
                    width: 100%;
                    height: 100%;
                }
            }
            .comment {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
`;

export const StyledTableNavigation = styled.div`
    margin-top: 31px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .navigation {
        display: flex;
        gap: 7px;
        align-items: center;
        .pageButton {
            width: 48px;
            color: #1C1D21;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0.21px;
            cursor: pointer;
            user-select: none;
            &.active {
                width: 32px;
                border-radius: 6px;
                background: ${({ theme }) => theme.themeColors.main};
                color: #FFF;
            }
        }
    }
`;

export const StyledButtonTable = styled.div<{disabled: boolean}>`
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: ${props => props.disabled ? "pointer" : "not-allowed"};
    ${StyledButton} {
        border-radius: 6px;
        background: #F5F5FA;
        width: 40px;
        height: 40px;
        cursor: ${props => props.disabled ? "pointer" : "not-allowed"};
        &:last-child {
            transform: rotate(180deg);
        }
    }
    span {
        color: #8181A5;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: ${props => props.disabled ? "1" : "0.5"};
    }
`;