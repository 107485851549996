import { axiosApi } from "shared/api/axios";
import { ProfileTypes } from "../types/ProfileTypes";

export const getOrderClients = async (id?: any): Promise<any> => {
    try {
        const response = await axiosApi.get<ProfileTypes>(`${process.env.API_BACKEND}/v1/bookings/order/?counterparty_filter=${id}`);
        return response.data;
    } catch(err) {
        console.error(err);
        throw new Error("Ошибка получения заказа");
    }
}