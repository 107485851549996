import { DashboardLayout } from "app/layout";
import { NomenclatureProfile } from "features/Nomenclature/ui/NomenclatureProfile";
import { FormikHelpers } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { axiosApi } from "shared/api/axios";
import { BackButton } from "shared/components/BackButton";
import { Button } from "shared/components/Button";
import { NOMENCLATURELIST } from "shared/consts/routingConsts";
import { closeModal, openModal } from "shared/redux/slice/modalSlice";
import { StyledItem } from "./style";
import { Input } from "shared/components/Input";
import { showNotification } from "shared/redux/slice/notificationSlice";
import { useQueryClient } from "@tanstack/react-query"
import { Autocomplete, AutocompleteOption } from "shared/components/Autocomplete";
import { useCounterpartiesQuery } from "entities/counterparties/model/hooks";

export const NomenclatureProfilePages = () => {
    const [selectedValue, setSelectedValue] = useState<AutocompleteOption | null>();
    const {counterparties} = useCounterpartiesQuery();

    const counterpartiesOptions: AutocompleteOption[] = useMemo(() => counterparties ? counterparties.map(item => ({
        value: item.id.toString(),
        label: `${item.contact.surname} ${item.contact.firstName} ${item.contact.patronymic}`,
    })) : [],[counterparties])


    const selectedValueRef = useRef<AutocompleteOption | null>(null);


    useEffect(() => {
        console.log(selectedValue)
    }, [selectedValue])

    const {id} = useParams();
    
    const queryClient = useQueryClient()

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [disabled, setDisabled] = useState<boolean>(false);
    const [count, setCount] = useState<number>(0);
    const [isEditable, setIsEditable] = useState(false);
    const [editButtonText, setEditButtonText] = useState("Редактировать");
    const [type, setType] = useState<number | null>();
    
    const formikRef = useRef<FormikHelpers<any>>(null);

    const toggleEdit = () => {
        setIsEditable(!isEditable);
        setEditButtonText(isEditable ? "Редактировать" : "Сохранить");
        if (isEditable && formikRef.current) {
            formikRef.current.submitForm();
        }
    };

    const cancelEdit = async () => {
        setIsEditable(false);
        setEditButtonText("Редактировать");
        formikRef.current?.resetForm();
        dispatch(closeModal());
    }

    const handleDeleteProfile = () => {
        axiosApi.delete(`${process.env.API_BACKEND}/v1/nomenclatures/nomenclature/`, {
            data: [id]
        });
        dispatch(closeModal());
        navigate(NOMENCLATURELIST)
    }

    const handleGoBack = () => {
        navigate(NOMENCLATURELIST);
    }

    const handleAddCountProduct = async (count: number, newCount: number) => {
        try {
            const response = await axiosApi.patch(`${process.env.API_BACKEND}/v1/nomenclatures/nomenclature/${id}`, {
                "count": newCount + count 
            });
            if (response.status === 200) {
                await queryClient.invalidateQueries(
                    {
                        queryKey: ["getNomenclatureSingle"],
                        refetchType: 'active',
                    },
                );
                dispatch(closeModal())
                dispatch(showNotification({ message: 'Успешно!', type: 'success' }));
            } else {
                dispatch(showNotification({ message: 'Ошибка!', type: 'error' }));
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleSellCountProduct = async (count: number, counterparty: number) => {
        try {
            const response = await axiosApi.post(`${process.env.API_BACKEND}/v1/quickorders/quickorder/`, {
                "nomenclatures": [
                    {
                        id: Number(id),
                        count: count
                    }
                ],
                "counterparty_id": counterparty
            });
            if (response.status === 201) {
                await queryClient.invalidateQueries(
                    {
                        queryKey: ["getNomenclatureSingle"],
                        refetchType: 'active',
                    },
                );
                dispatch(closeModal())
                dispatch(showNotification({ message: 'Успешно!', type: 'success' }));
            } else {
                dispatch(showNotification({ message: 'Ошибка!', type: 'error' }));
            }
        } catch (err) {
            console.log(err);
        }
    }


    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <StyledItem>
                    <BackButton 
                        text="Назад"
                        onClick={handleGoBack}
                    />
                    <div className="dashboard__header-title">{type === 0 ? "Оборудование" : "Расходные материалы"}</div>
                </StyledItem>
                <div className="dashboard__right-menu">
                {isEditable ? (
                        <>
                            <Button requiredPermission="edit" themeType={isEditable ? "primary" : "outline-2"} onClick={toggleEdit} disabled={disabled}>{editButtonText}</Button>
                            <Button requiredPermission="delete" themeType={"outline-2"} onClick={cancelEdit}>Отменить</Button>
                        </>
                    ) : (
                        <>
                        <Button
                                requiredPermission="save"
                                onClick={() => {
                                let newCount = "";
                                
                                selectedValueRef.current = selectedValue ? selectedValue : null;
                                dispatch(openModal({
                                isOpen: true,
                                content: (
                                    <div className="blockWithComment modalNomenclatureSelling">
                                        <Input 
                                            label="Сколько товара продать?" 
                                            // type="number" 
                                            placeholder="Введите кол-во"  
                                            onAccept={(value: string) => (newCount = value)}
                                            maskConfig={{
                                                mask: Number,
                                                min: 1,
                                                max: count,
                                                autofix: true,
                                                validate: (value: any) => {
                                                    return /^(?!0)\d+$/.test(value)
                                                },
                                            }}
                                        />
                                        <Autocomplete
                                            placeholder="Выберите клиента"
                                            options={counterpartiesOptions}
                                            value={selectedValue ?? null}
                                            // isLoading={isFetching}
                                            // onLoadMore={fetchNextPage}
                                            onChange={(value) => {
                                                setSelectedValue(value);
                                                selectedValueRef.current = value;
                                            }}
                                        />
                                    </div>
                                ),
                                config: {
                                    title: "Продать товар",
                                    btns: [
                                        {
                                            onClick: () => handleSellCountProduct(Number(newCount), Number(selectedValueRef.current?.value)),
                                            title: "Продать",
                                            active: true
                                        },
                                        {
                                            onClick: () => dispatch(closeModal()),
                                            title: "Отменить",
                                            active: false
                                        }
                                    ]
                                }
                            }))}}
                            >Продать товар</Button>
                            <Button 
                                requiredPermission="add"
                                onClick={() => {
                                let newCount = "";
                                dispatch(openModal({
                                isOpen: true,
                                content: (
                                    <div className="blockWithComment">
                                        <Input 
                                            label="Сколько товара добавить?" 
                                            placeholder="Введите кол-во"
                                            onChange={(e) => newCount = (e.target as HTMLInputElement).value}
                                            maskConfig={{
                                                min: 1,
                                                mask: Number, 
                                                max: 9999,
                                                autofix: true,
                                                validate: (value: any) => {
                                                    return !/^[-]|^0(?!\.)/.test(value)
                                                },
                                                // parse: (str: string, masked: Masked) => {
                                                //     console.log(str, masked);
                                                // },
                                                overwrite: true,
                                            }}
                                        />
                                    </div>
                                ),
                                config: {
                                    title: "Добавить товар",
                                    btns: [
                                        {
                                            onClick: () => handleAddCountProduct(count, Number(newCount)),
                                            title: "Добавить",
                                            active: true,
                                            // isLoading: true
                                        },
                                        {
                                            onClick: () => dispatch(closeModal()),
                                            title: "Отменить",
                                            active: false
                                        }
                                    ]
                                }
                            }))}}
                            >Дополнить товар</Button>
                            <Button requiredPermission="edit" themeType={"outline-2"} onClick={toggleEdit}>{editButtonText}</Button>
                            <Button 
                                requiredPermission="delete"
                                themeType="delete" 
                                onClick={() => dispatch(openModal({
                                    isOpen: true,
                                    content: (
                                        <>
                                            Вы точно хотите удалить оборудование?
                                        </>
                                    ),
                                    config: {
                                        title: "Вы точно хотите удалить?",
                                        btns: [
                                            {
                                                onClick: () => handleDeleteProfile(),
                                                title: "Да",
                                                active: true
                                            },
                                            {
                                                onClick: () => dispatch(closeModal()),
                                                title: "Нет",
                                                active: false
                                            }
                                        ]
                                    }
                                }))}
                            >
                                Удалить
                            </Button>

                        </>
                    )
                }
                </div>
            </div>
        }>
            <NomenclatureProfile
                setDisabled={setDisabled}
                setType={setType}
                isEditable={isEditable}
                formikRef={formikRef}
                setIsEditable={setIsEditable}
                setEditButtonText={setEditButtonText}
                setCount={setCount}
            />
        </DashboardLayout>
    )
}