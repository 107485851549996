import { DashboardLayout } from 'app/layout'
import { areaHeader, card, clientsHeader, dropdownPeriod } from './statics'
import { Card } from './components/Card'
import { useEffect, useMemo, useState } from 'react'
import { Row } from './style'
import { DropdownPeriod } from 'shared/components/Dropdown'
import moment from 'moment'
import { ChartLine } from 'shared/components/ChartLine'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { getMetrics } from './api/getMetrics'
import { OrdersTable } from 'features/OrdersTable'
import { aggregateDataByHour } from 'shared/utils/aggregateDataByHour'
import { AggregatedData } from 'shared/types/metricsTypes'
import { Loader } from 'shared/components/Loader'
import { TableDashboard } from './components/TableDashboard'
import { ChartPie } from 'shared/components/ChartPie'

interface MetricsTotal {
    total_revenue?: number
    total_canceled_bookings?: number
    total_returns?: number
    total_day_orders?: number
    week_start?: string
}

export const Dashboard = () => {
    const [selectedPeriod, setSelectedPeriod] = useState(dropdownPeriod[0])
    const [aggregateData, setAggregateData] = useState<AggregatedData[] | null>(
        null,
    )
    const [, setLables] = useState<string[] | null>([])

    const [query, setQuery] = useState<string>(
        `daily?day=${moment().format('YYYY-MM-DD')}`,
    )

    const { isLoading, data, isFetching, isPending } =
        useQuery({
            queryKey: ['getMetrics', selectedPeriod, query],
            queryFn: () => {
                return getMetrics(query)
            },
            placeholderData: keepPreviousData,
            refetchOnWindowFocus: false,
            retry: 3,
            staleTime: 1000 * 60,
        })

    useEffect(() => {
        switch (selectedPeriod) {
            case 'Сегодня':
                setQuery(`daily?day=${moment().format('YYYY-MM-DD')}`)
                break
            case 'Неделя':
                setQuery(
                    `weekly?start_day=${moment().format('YYYY-MM-DD')}&end_day=${moment().add(7, 'days').format('YYYY-MM-DD')}`,
                )
                break
            case 'Месяц':
                setQuery(`monthly?month=${moment().format('YYYY-MM-DD')}`)
        }
    }, [selectedPeriod])

    useEffect(() => {
        if (data && data.length > 0) { 
            if (selectedPeriod === 'Сегодня') {
                const aggregated = aggregateDataByHour(data[0].data)
                setAggregateData(aggregated as any)
                setLables(
                    aggregated.map(item => moment(item.hour).format('HH')),
                )
            } else if (selectedPeriod === 'Неделя') {
                setAggregateData(data[0].data as any)
                setLables(
                    data[0].data.map(item =>
                        moment(item.created_at).format('dddd'),
                    ),
                )
            } else if (selectedPeriod === 'Месяц') {
                setAggregateData(data[0].data as any)
                setLables(
                    data[0].data.map(item =>
                        moment(item.created_at).format('DD'),
                    ),
                )
            }
        } else {
            console.warn("Нет доступных данных", data);
        }
    }, [data, selectedPeriod])

    const computedLabels = useMemo(() => {
        if (!aggregateData) return []
        if (data) {
            return selectedPeriod === 'Сегодня'
                ? aggregateData.map(item => moment(item.hour).format('HH'))
                : selectedPeriod === 'Неделя'
                ? data[0].data.map(item =>
                    moment(item.created_at).format('dddd'),
                ) : []
        }
    }, [aggregateData, selectedPeriod, data])

    if(isFetching && isPending) {
        <Loader />
    }

    return (
        <DashboardLayout
            header={
                <div className='dashboard__header'>
                    <div className='dashboard__header-title'>Дашборд</div>
                    <div className='dashboard__right-menu'>
                        <DropdownPeriod
                            selected={selectedPeriod}
                            setSelected={setSelectedPeriod}
                        />
                    </div>
                </div>
            }
        >
            <Row>
                {!isLoading && data && (
                    <>
                        {card.map((item, index) => (
                            <Card
                                key={index}
                                title={item.title}
                                amount={
                                    data[0]?.total?.[
                                        item.keyData as keyof MetricsTotal
                                    ] || 0
                                }
                                icon={item.icon}
                                loading={isLoading && isPending}
                                showCurrency={item.showCurrency}
                            />
                        ))}
                    </>
                )}
            </Row>
            <Row>
                {(isLoading || isFetching || isPending) ? <Loader /> : (
                    <div className='chart'>
                        {data && aggregateData && (
                            <ChartLine
                                labels={computedLabels as any}
                                dataAccepted={aggregateData.map(
                                    item => item.total_orders,
                                )}
                                dataCancelled={aggregateData.map(
                                    item => item.canceled_bookings,
                                )}
                            />
                        )}
                    </div>
                )}
                <ChartPie 
                    title="Процент заказов"
                />
                <ChartPie 
                    title="Заполненность залов"
                />
            </Row>
            {data &&
                <Row>
                    <TableDashboard 
                        title='Топ клиентов'
                        headerRow={clientsHeader}
                        bodyRow={data[0] && data[0].total ? data[0].total?.top_clients : []}
                    />
                    <TableDashboard 
                        title='Распределение по залам'
                        headerRow={areaHeader}
                        bodyRow={data[0] && data[0].equipment_booking_info ? data[0].equipment_booking_info : []}
                    />
                </Row>
            }
            <Row>
                <OrdersTable />
            </Row>
        </DashboardLayout>
    )
}
