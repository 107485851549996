import { axiosApi } from "shared/api/axios";
import { GetOrgsTypes } from "../types";

export const getOrg = async (id?: any): Promise<GetOrgsTypes> => {
    try {
        const response = await axiosApi.get<GetOrgsTypes>(`${process.env.API_BACKEND}/v1/organizations/organization/${id}`);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Ошибка получения профиля");
    }
};