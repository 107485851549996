import { ColumnDef, PaginationState, SortingState, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { StyledButtonTable, StyledTable, StyledTableBody, StyledTableHead, StyledTableNavigation } from "./style";
import React, { useEffect, useMemo, useState } from "react";
import { Checkbox } from "../Checkbox";
import { Button } from "../Button";
import { Icon } from "../Icons";
import { useNavigate } from "react-router-dom";
import { Row } from "@tanstack/react-table";

interface IPropsReactTable<T> {
    data: T[];
    columns: ColumnDef<T>[];
    className?: string;
    columnWidths?: string[];
    selectedRows?: T[];
    onSelectionChange?: (selectedRows: T[]) => void;
    navigation?: string;
    totalPages?: number;
    onPageChange?: (page: number) => void;
    onSortChange?: (sorting: SortingState) => void;
    isLoading?: boolean;
    isError?: boolean;
    disableSorting?: boolean;
    disablePagination?: boolean;
    disableSelection?: boolean;
    page?: number;
}

interface TableRow {
    id: number;
}

export const Table = <T extends TableRow>({ 
    data = [], 
    columns, 
    className, 
    columnWidths,
    selectedRows = [],
    onSelectionChange,
    navigation,
    totalPages,
    onPageChange,
    onSortChange,
    isLoading,
    isError,
    disableSorting = false,
    disablePagination = false,
    disableSelection = false,
    page = 0
}: IPropsReactTable<T>) => {
    const navigate = useNavigate();

    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: page,
        pageSize: 10,
    });

    const [sorting, setSorting] = useState<SortingState>([
        { id: 'created_at', desc: false }
    ]);

    useEffect(() => {
        if (onPageChange && !disablePagination) {
            onPageChange(pagination.pageIndex);
        }
    }, [pagination.pageIndex, disablePagination]); // убрал onPageChange. Надо бы вспомнить зачем оно вообще тут. Вызывает кучу рендерово

    useEffect(() => {
        if (onSortChange && !disableSorting) {
            onSortChange(sorting);
        }
    }, [sorting, onSortChange, disableSorting]);

    const enhancedColumns = useMemo(() => {
        if (disableSelection) {
            return columns;
        }

        return [
            {
                id: "selection",
                header: () => (
                    <Checkbox
                        name="checkbox"
                        checked={selectedRows.length === data.length && data.length > 0}
                        onChange={(e) => {
                            const isChecked = e.target.checked;
                            const newSelectedRows = isChecked ? [...data] : [];
                            if (onSelectionChange) {
                                onSelectionChange(newSelectedRows);
                            }
                        }}
                    />
                ),
                cell: ({ row }: { row: Row<T> }) => (
                    <Checkbox
                        name={String(row.id)}
                        checked={selectedRows.some(
                            (selectedRow) => selectedRow.id === row.original.id
                        )}
                        onMouseDown={(e) => e.stopPropagation()}
                        onChange={(e) => {
                            const isChecked = e.target.checked;
                            const newSelectedRows = isChecked
                                ? [...selectedRows, row.original]
                                : selectedRows.filter(
                                    (selectedRow) => selectedRow.id !== row.original.id
                                );
                            if (onSelectionChange) {
                                onSelectionChange(newSelectedRows);
                            }
                        }}
                    />
                )
            },
            ...columns
        ];
    }, [columns, selectedRows, data, onSelectionChange, disableSelection]);

    const table = useReactTable({
        data,
        columns: enhancedColumns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: disableSorting ? undefined : getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: disablePagination ? undefined : getPaginationRowModel(),
        onPaginationChange: setPagination,
        pageCount: totalPages && totalPages + 1,
        manualPagination: true,
        manualSorting: true,
        state: {
            pagination,
            sorting
        },
        onSortingChange: setSorting,
    });

    const handleRowClick = (id: number) => {
        if (navigation) {
            navigate(`${navigation}/${id}`);
        }
    };

    const nonSortableColumns = ["selection", "photo", "comment"];

    const handleSortingChange = (columnId: string) => {
        if (disableSorting || nonSortableColumns.includes(columnId)) {
            return;
        }
        setSorting((prev) => {
            const isExisting = prev.find(sort => sort.id === columnId);
            if (isExisting) {
                const newDesc = isExisting.desc ? false : true;
                return [{ id: columnId, desc: newDesc }];
            } else {
                return [{ id: columnId, desc: false }];
            }
        });
    };

    const widths = useMemo(() => {
        const defaultWidths = columnWidths || [];
        return [
            ...defaultWidths,
            ...Array(columns.length - 1).fill('1fr')
        ];
    }, [columnWidths, columns.length]);

    useEffect(() => {
        if(totalPages) {
            if (pagination.pageIndex >= totalPages + 1) {
                setPagination((prev) => ({
                    ...prev,
                    pageIndex: 1,
                }));
            }
        }
    }, [totalPages, data]);

    return (
        <StyledTable className={className}>
            <StyledTableHead column={enhancedColumns.length} columnwidths={widths}>
                {table.getHeaderGroups().map((headerGroup) => (
                    <React.Fragment key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <>
                                {disableSorting ? (
                                    <div
                                        className="styledTableHeadColumn" 
                                        key={header.id}
                                        onClick={() => handleSortingChange(header.id)}
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                    </div>
                                ) : (
                                    <button 
                                        className="styledTableHeadColumn" 
                                        key={header.id}
                                        onClick={() => handleSortingChange(header.id)}
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        {header.column.getIsSorted() === "asc" ? <Icon name="sortingDesc" /> : header.column.getIsSorted() === "desc" ? <Icon name="sortingAsc" /> : ""}
                                    </button>
                                )
                                }
                            </>
                        ))}
                    </React.Fragment>
                ))}
            </StyledTableHead>
            {isLoading && <div className="notFound">Загрузка...</div>}
            {isError && <div className="notFound">Ошибка:(</div>}
            <>
                {data && data.length !== 0 ?
                    <>
                        <StyledTableBody column={enhancedColumns.length} columnwidths={widths}>
                            {table.getRowModel().rows.map(row => (
                                <div 
                                    className="row" 
                                    key={row.id}
                                    style={{ cursor: navigation ? 'pointer' : 'default' }}
                                >
                                    {row.getVisibleCells().map(cell => {
                                        if(cell.column.columnDef.id === "selection") {
                                            return <div className="bodyCell" key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                                        } else {
                                            return <div className="bodyCell" onClick={() => handleRowClick(row.original.id)} key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                                        }
                                    })}
                                </div>
                            ))}
                        </StyledTableBody>
                        {!disablePagination && totalPages && onPageChange ? (
                            <StyledTableNavigation>
                                <StyledButtonTable  
                                    onClick={() => {
                                        if (table.getState().pagination.pageIndex !== 1) {
                                            table.previousPage();
                                        }
                                    }}
                                    disabled={table.getState().pagination.pageIndex !== 1 ? true : false}
                                >
                                    {table.getCanPreviousPage()}
                                    <Button disabled={!table.getCanPreviousPage()}>
                                        <Icon name="arrowRight" />
                                    </Button>
                                    <span>Назад</span>
                                </StyledButtonTable>

                                <div className="navigation">
                                    {pagination.pageIndex > 3 &&
                                        <span 
                                            className={`pageButton ${1 === pagination.pageIndex ? 'active' : ''}`} 
                                            onClick={() => table.setPageIndex(1)}
                                        >
                                            1
                                        </span>
                                    }

                                    {pagination.pageIndex > 3 && <span className="dots">...</span>}

                                    {[...Array(totalPages)].map((_, index) => {
                                        if (
                                            index >= pagination.pageIndex - 2 && 
                                            index <= pagination.pageIndex + 2 && 
                                            index !== 0 && 
                                            index !== totalPages
                                        ) {
                                            return (
                                                <span 
                                                    className={`pageButton ${index === pagination.pageIndex ? 'active' : ''}`} 
                                                    key={index}
                                                    onClick={() => table.setPageIndex(index)}
                                                >
                                                    {index}
                                                </span>
                                            );
                                        }
                                        return null;
                                    })}

                                    {pagination.pageIndex < totalPages - 4 && <span className="dots">...</span>}

                                    {totalPages > 1 && (
                                        <span 
                                            className={`pageButton ${totalPages === pagination.pageIndex ? 'active' : ''}`} 
                                            onClick={() => table.setPageIndex(totalPages)}
                                        >
                                            {totalPages}
                                        </span>
                                    )}
                                </div>

                                <StyledButtonTable  
                                    onClick={() => table.nextPage()}
                                    disabled={table.getCanNextPage()}
                                >
                                    <span>Далее</span>
                                    <Button disabled={!table.getCanNextPage()}>
                                        <Icon name="arrowRight" />
                                    </Button>
                                </StyledButtonTable>
                            </StyledTableNavigation>
                        ) : null}
                    </>
                : <>{(!isLoading && !isError) && <div className="notFound">Список пуст</div>}</>
                }
            </>
        </StyledTable>
    )
}